.menu-button{
  color: #EF4231;
  filter: grayscale(100%);
  text-decoration: none;
}

.menu-button:hover, .menu-button.active{
  filter: grayscale(0%);
}

ul .dashed {
  list-style-type: none;
}
ul .dashed li:before{
  content: '\2013';
  position: absolute;
  margin-left: -20px;
}
